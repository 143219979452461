import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import Input from "../../../../ui/components/input/input";

export type AmazonS3OptionsType = {
  type: "AMAZON_S3",
  accessKey: string,
  secretKey: string,
  bucketId: string,
  region: string,
}

export const Validation = Yup.object().shape({
  accessKey:  Yup.string().required('You need to put your access key.'),
  secretKey:  Yup.string().required('You need to put your secret key.'),
  bucketId:  Yup.string().required('You need to put your bucket Id.'),
  region:  Yup.string().required('You need to put your region.'),
});


const AmazonS3Options = ({ formik } : {formik: any}) => {
  const { t } = useTranslation();
  return (
    <div className="py-6 px-4 space-y-6 sm:p-6">
      <div>
        <Input
          id="accessKey"
          name="accessKey"
          type="text"
          label={t('Access key')}
          error={formik.errors.options && formik.errors.options.accessKey}
          touched={formik.touched.options && formik.touched.options.accessKey}
          description={t('Input the Access Key of your Amazon S3 storage')}
          value={formik.values.options.accessKey}
          onChange={(e) => formik.setFieldValue('options.accessKey', e.target.value)}
        />
      </div>
      <div>
        <Input
          id="secretKey"
          name="secretKey"
          type="text"
          label={t('Secret Key')}
          error={formik.errors.options && formik.errors.options.secretKey}
          touched={formik.touched.options && formik.touched.options.secretKey}
          description={t('Input the Secret Key of your Amazon S3 storage')}
          value={formik.values.options.secretKey}
          onChange={(e) => formik.setFieldValue('options.secretKey', e.target.value)}
        />
      </div>
      <div>
        <Input
          id="region"
          name="region"
          type="text"
          label={t('Region')}
          error={formik.errors.options && formik.errors.options.region}
          touched={formik.touched.options && formik.touched.options.region}
          description={t('Input the region of your Amazon S3 storage')}
          value={formik.values.options.region}
          onChange={(e) => formik.setFieldValue('options.region', e.target.value)}
        />
      </div>
      <div>
        <Input
          id="bucketId"
          name="bucketId"
          type="text"
          label={t('Bucket ID')}
          error={formik.errors.options && formik.errors.options.bucketId}
          touched={formik.touched.options && formik.touched.options.bucketId}
          description={t('Input the bucket ID of your Amazon S3 storage')}
          value={formik.values.options.bucketId}
          onChange={(e) => formik.setFieldValue('options.bucketId', e.target.value)}
        />
      </div>
    </div>
  );
};

export default AmazonS3Options;
