import API from "../../../api/http/client";
import Proxy from "../../../api/http/proxy";
import ApiModel from "../../../models/http/data-connector/api/api";
import DeleteSerializer from "../../../models/http/delete";

class APIStreamAPI extends API{

  serializer = () => ({
    _postOne : ApiModel,
    _put: ApiModel,
    _getAll: ApiModel,
    _getOne: ApiModel,
    _delete: DeleteSerializer
  })

}

export default Proxy<APIStreamAPI>(new APIStreamAPI('/api/saved'))
