import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import Input from "../../../../ui/components/input/input";
import Textarea from "../../../../ui/components/input/textarea";

export type GoogleCloudStorageOptionsType = {
  type: "GOOGLE_CLOUD_STORAGE",
  jsonKey: string,
  bucketId: string,
}

export const Validation = Yup.object().shape({
  bucketId:  Yup.string().required('You need to put your bucket Id.'),
  jsonKey:  Yup.string().required('You need to put your Json Key.'),
});


const GoogleCloudStorageOptions = ({ formik } : {formik: any}) => {
  const { t } = useTranslation();
  return (
    <div className="py-6 px-4 space-y-6 sm:p-6">
      <div>
        <Textarea
          id="jsonKey"
          name="jsonKey"
          rows={4}
          label={t('Json Key')}
          error={formik.errors.options && formik.errors.options.jsonKey}
          touched={formik.touched.options &&  formik.touched.options.jsonKey}
          description={t('Input the JsonKey that you use to connect from developer console.')}
          value={formik.values.options.jsonKey}
          onChange={(e) => formik.setFieldValue('options.jsonKey', e.target.value)}
        />
      </div>
      <div>
        <Input
          id="bucketId"
          name="bucketId"
          type="text"
          label={t('Bucket ID')}
          error={formik.errors.options && formik.errors.options.bucketId}
          touched={formik.touched.options && formik.touched.options.bucketId}
          description={t('Input the bucket ID of your Google Cloud Storage storage')}
          value={formik.values.options.bucketId}
          onChange={(e) => formik.setFieldValue('options.bucketId', e.target.value)}
        />
      </div>
    </div>
  );
};

export default GoogleCloudStorageOptions;
