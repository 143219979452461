import { JSONSchemaType } from "ajv/dist/types/json-schema";
import { NETWORK } from "../../../../../constant/network";
import Model from "../../../model";
import { PostRequest } from "../../../form-interface";
import { QUERY_FORM } from "../../../../../features/data-connectors/api/type";

export interface SavedAPI {
  id: number,
  name: string,
  createdAt: string,
  company: string,
  json: QUERY_FORM
}

export interface PostSavedAPI {
  name: string,
  json: QUERY_FORM
}



class SavedAPIModel extends Model<SavedAPI> implements PostRequest<PostSavedAPI>{

  getId(){
    return this.data.id;
  }

  getName(){
    return this.data.name;
  }

  getCreatedAt() {
    return this.data.createdAt;
  }

  getCompany(){
    return this.data.company;
  }

  getJson(){
    return this.data.json;
  }


  baseSchemaToSerialize = () : JSONSchemaType<SavedAPI> => ({

    type: "object",
    properties: {
      "id": {
        type: "integer"
      },
      "name": {
        type: "string"
      },
      "createdAt": {
        type: "string"
      },
      "company": {
        type: "string"
      },
      "json": {
        type: "object",
        additionalProperties: true,
        required: [],
      }
    },
    required: ['id', 'name', 'createdAt', 'company', 'json']
  });

  baseGetSchemaPostToValidate = (): JSONSchemaType<PostSavedAPI> => ({
    type: "object",
    properties: {
      "name": {
        type: "string"
      },
      "json": {
        type: "object",
        additionalProperties: true,
        required: [],
      }
    },
    required: ['name', 'json']
  })

  getSchemaPostToValidate(): JSONSchemaType<PostSavedAPI> {
    return this.baseGetSchemaPostToValidate() as JSONSchemaType<PostSavedAPI>;
  }

  getSchemaToSerialize(): JSONSchemaType<SavedAPI> {
    return this.baseSchemaToSerialize() as JSONSchemaType<SavedAPI>;
  }
}

export default SavedAPIModel;
