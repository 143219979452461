import { useMutation, useQuery } from "react-query";
import FieldAPI from "../../services/repository/google-datastudio/field-dto";
import API from "../../services/repository/data-connector/api/api";
import { NETWORK } from "../../constant/network";
import Pagination from "../../services/models/http/paginated";
import FieldDTO from "../../services/models/http/google-datastudio/field-dto";
import ConfigFieldAPI from "../../services/repository/google-datastudio/config-dto";
import ConfigDto from "../../services/models/http/google-datastudio/config-dto";
import { PostSavedAPI } from "../../services/models/http/data-connector/api/api";

export const useGetField = (network: NETWORK)  => useQuery(['field-dto', network], () : Promise<Pagination<FieldDTO>> => FieldAPI._getAll({ query: {platform: network} }), {
  enabled: !!network,
});

export const useConfigField = (network: NETWORK)  => useQuery(['config-field', network], () : Promise<Pagination<ConfigDto>> => ConfigFieldAPI._getAll({ query: {platform: network} }), {
  enabled: !!network,
});


export const usePostSaveQuery = () => {
  return useMutation((data: PostSavedAPI) => API._postOne(data))
}

export const usePutSaveQuery = () => {
  return useMutation((data: PostSavedAPI&{id:number}) => API._put(data.id, data))
}

export const useGetSaveQuery = () => {
  return useQuery('get-save-query', () => API._getAll())
}
