import { JSONSchemaType } from "ajv/dist/types/json-schema";
import Model from "../../model";

export interface NetworkAuthorizationHTTP {
  id: number,
  name: string
  platform: string,
  state: "SUCCESS" | "ERROR" | "ACCOUNT_NOT_AVAILABLE",
  lastRefresh?: string,
  numberError?: number,
  totalAvailableDataSource?: number,
  totalActivatedDataSource?: number,
  status: "SYNC_STATUS_SUCCESS" | "SYNC_STATUS_ERROR",
  dataSources: Array<{
    accountId: string,
    name: string,
    id: number
  }>,
  options:{
    [key:string] : string|number
  } | Array<string>,
  availableDataSource: Array<{
    accountId: string,
    name: string
  }>|null
}

class NetworkAuthorization extends Model<NetworkAuthorizationHTTP>{

  getId(){
    return this.data.id;
  }

  getName(){
    return this.data.name;
  }

  getNumberError(){
    return this.data.numberError || 0;
  }

  getPlatform(){
    return this.data.platform;
  }

  getState(){
    return this.data.state;
  }

  getStatus(){
    return this.data.status;
  }

  getLastRefreshed(){
    return this.data.lastRefresh;
  }

  getDataSources(){
    return this.data.dataSources;
  }

  getTotalActivatedDataSource(){
    return this.data.totalActivatedDataSource;
  }

  getTotalAvailableDataSource(){
    return this.data.totalAvailableDataSource;
  }

  getAvailableDataSource(){
    return this.data.availableDataSource ? this.data.availableDataSource : [];
  }

  getSchemaToSerialize = (): JSONSchemaType<NetworkAuthorizationHTTP> => ({
    type: "object",
    properties: {
      "id": { type: "number" },
      "name": { type: "string" },
      "platform": { type: "string" },
      "state": { type: "string" },
      "status": { type: "string" },
      "numberError": { type: "number", nullable: true },
      "totalAvailableDataSource": { type: "number", nullable: true },
      "totalActivatedDataSource": { type: "number", nullable: true},
      "dataSources": {
        type: "array",
        items: {
          type: "object",
          properties: {
            name: {
              type: "string"
            },
            accountId: {
              type: "string"
            },
            id: {
              type: "number"
            }
          },
          required: ["id", "name", "accountId"]
        }
      },
      "options" : {
        oneOf: [{
          type: "object",
          "patternProperties": {
            ".*": {
              type: ["number", "string"]
            }
          },
          required: []
        },{
          type: "array",
          items: {
            type: "string"
          }
        }]
      },
      "availableDataSource": {
        type: "array",
        items: {
          type: "object",
          properties : {
            name: {
              type: "string"
            },
            accountId: {
              type: "string"
            }
          },
          required: ["name", "accountId"]
        },
        nullable: true
      },
      "lastRefresh": {
        type: "string" ,
        nullable: true
      },
    },
    required: ['id', 'name', 'platform', 'state', 'status', 'availableDataSource', 'dataSources', "options"]
  });
}

export default NetworkAuthorization;
